import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";
import EbayPolicies from './EbaySettings/components/EbayPolicies';
import EbayLocations from './EbaySettings/components/EbayLocations';
import TooltipAtCustomElement from "../../../shared/components/TooltipAtCustomElement";
import Select from "react-select";
import MaskedInput from 'react-text-mask';
import { get_marketplace_mapping } from "../../../helpers/utility";

const IconTooltip = () => <span className="icon icon-help-circle"></span>

const handlingSelect = [
  {label: "Process Immediately", value: 0},
  {label: "1 day", value: 24*60*60},
  {label: "2 days", value: 2*24*60*60},
  {label: "3 days", value: 3*24*60*60},
];

class EbayListings extends Component {

  state = {
    phone_number: null,
  }

	updateMarkAsShipped = (value) => {
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["ebay_mark_fba_orders_shipped"] = !ud.settings["ebay_mark_fba_orders_shipped"];
			this.props.updateUserData(ud);
			let data = {ebay_mark_fba_orders_shipped: ud.settings["ebay_mark_fba_orders_shipped"]};
			this.props.updateUserSettings(data);
		}
	}

	updateEbayTruncateTitle = (value) => {
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["ebay_truncate_title"] = !ud.settings["ebay_truncate_title"];
			this.props.updateUserData(ud);
			let data = {ebay_truncate_title: ud.settings["ebay_truncate_title"]};
			this.props.updateUserSettings(data);
		}
	}

  updateEbayAutoPopulateDesc = () => {
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
			ud.settings["ebay_auto_populate_desc"] = !ud.settings["ebay_auto_populate_desc"];
			this.props.updateUserData(ud);
			let data = {ebay_auto_populate_desc: ud.settings["ebay_auto_populate_desc"]};
			this.props.updateUserSettings(data);
		}
  }

	selectHandling = (e) => {
	  const { userData } = this.props;
		if(userData){
			let ud = userData;
      if(!e){
        ud.settings["ebay_handling_time"] = 0;
      } else {
  			ud.settings["ebay_handling_time"] = e.value;
      }
			this.props.updateUserData(ud);
			let data = {ebay_handling_time: ud.settings["ebay_handling_time"]};
			this.props.updateUserSettings(data);
		}
  }

  checkPhoneNumberValid = () => {
    const { phone_number } = this.state;
    if(phone_number !== null && phone_number.trim() && phone_number.trim().length === 10){
      return true;
    }
    return false;
  }

  updatePhoneNumber = () => {
    const { phone_number } = this.state;
    if(this.checkPhoneNumberValid()){
  		const data = {"ebay_phone_number": phone_number.trim()}
      this.props.ebayUserUpdateSettings(data);
    }
	}

  render() {
    const { userData } = this.props;

    const marketplaceMap = get_marketplace_mapping(false, userData.marketplaceId);
    let callingCode = "+1";
    let phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    if(marketplaceMap.value === "UK") {
      callingCode = "+44";
      phoneMask = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    }

		return (
		<React.Fragment>
			<Row>
				<Col md={5}>
					<EbayPolicies />
          <Row>
            <Col>
              <FormGroup className="mt-4" style={{ maxWidth: '28rem' }}>
                <div className="d-flex align-items-center">
                  <Label>SMS on eBay Sale</Label>&nbsp;
                  <span className="help" data-toggle="tooltip">
                      <TooltipAtCustomElement
                        tooltipId="tooltip-sms"
                        tooltipText={`Provide AccelerList with your cell phone number. We'll text you every time you get an eBay Sale!`}
                        CustomElement={IconTooltip}
                        styles={{ maxWidth: "20rem" }}
                      />
                  </span>
                </div>
                <InputGroup>
                  <InputGroupAddon addonType="prepend"><InputGroupText>{callingCode}</InputGroupText></InputGroupAddon>
                  <MaskedInput
                    mask={phoneMask}
                    onChange={e => this.setState({"phone_number": e.target.value.replace(/\D+/g, '')})}
                    className="form-control"
                    showMask={true}
                    keepCharPositions={true}
                    value={this.state.phone_number !== null
                      ? this.state.phone_number
                      : this.state.phone_number === null
                        ? this.props.ebay_user_settings?.ebay_phone_number
                        : this.state.phone_number
                    }
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="success"
                      onClick={() => this.updatePhoneNumber()}
                      disabled={this.props.ebay_user_settings_updating || !this.checkPhoneNumberValid()}
                    >Update</Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
				</Col>
				<Col md={7}>
					<EbayLocations />
					<Card className="mt-4">
						<CardBody>
							<CardTitle>Product Listing</CardTitle>
							<FormGroup className="mt-4">
								<FormGroup check className="custom-check checkbox">
									<Label check className="mr-2">
										<Input
											type="checkbox"
											checked={userData && userData.settings && userData.settings.ebay_mark_fba_orders_shipped ? true : false}
											onChange={e => this.updateMarkAsShipped()}
										/> <span>Mark FBA orders as shipped on eBay to prevent handling time expirations</span>
									</Label>
									<span className="help" data-toggle="tooltip">
										<TooltipAtCustomElement
											tooltipId="tooltip-mark-shipped"
											tooltipText={`Because Amazon is often slow to ship items, many customers experience eBay handling time
											expirations which can cause eBay account penalties. So by default, AccelerList marks an item
											as shipped to indicate to the eBay buyer that their order has been processed, based on selection below.`}
											CustomElement={IconTooltip}
											styles={{ maxWidth: "20rem" }}
										/>
									</span>
								</FormGroup>
							</FormGroup>
							<FormGroup row className="align-items-center">
								<Col sm="5">
									<Label>
										Select max handling time for ebay orders
										<span className="help ml-2" data-toggle="tooltip">
											<TooltipAtCustomElement
												tooltipId="tooltip-handling-time"
												tooltipText={`If this threshold is exceeded we will mark item as shipped. Also if this option is selected,
												above option and not immediately mark FBA orders as shipped on eBay`}
												CustomElement={IconTooltip}
												styles={{ maxWidth: "20rem" }}
											/>
										</span>
									</Label>
								</Col>
								<Col sm="4">
									<Select
										options={handlingSelect}
										onChange={e => this.selectHandling(e)}
										value={
											userData && userData.settings
												? handlingSelect.find(item => item.value === userData.settings.ebay_handling_time)
												: null
										}
									/>
								</Col>
							</FormGroup>
							<FormGroup>
								<FormGroup check className="custom-check checkbox">
									<Label check className="mr-2">
										<Input
											type="checkbox"
											checked={userData?.settings?.ebay_truncate_title ? true : false}
											onChange={e => this.updateEbayTruncateTitle()}
										/> <span>Auto-truncate product title when cross-listing</span>
									</Label>
									<span className="help" data-toggle="tooltip">
										<TooltipAtCustomElement
											tooltipId="tooltip-auto-truncate"
											tooltipText={`Let AccelerList truncate your product title length down to the acceptable amount allowed by eBay.`}
											CustomElement={IconTooltip}
											styles={{ maxWidth: "20rem" }}
										/>
									</span>
								</FormGroup>
							</FormGroup>
							<FormGroup>
								<FormGroup check className="custom-check checkbox">
									<Label check className="mr-2">
										<Input
											type="checkbox"
											checked={userData?.settings?.ebay_auto_populate_desc ? true : false}
											onChange={e => this.updateEbayAutoPopulateDesc()}
										/> <span>Auto-populate product description when cross-listing</span>
									</Label>
									<span className="help" data-toggle="tooltip">
										<TooltipAtCustomElement
											tooltipId="tooltip-auto-populate"
											tooltipText={`Let AccelerList populate your product description from Amazon to eBay listing.`}
											CustomElement={IconTooltip}
											styles={{ maxWidth: "20rem" }}
										/>
									</span>
								</FormGroup>
							</FormGroup>
						</CardBody>
					</Card>
				</Col>
			</Row>
      {/*
			<hr />
			<Row>
				<Col md={6}>
					<Card>
						<CardBody>
							<CardTitle>Default eBay Listings Settings</CardTitle>

							<FormGroup row>
								<Label sm="5">Preferred List Quantity</Label>
								<Col sm="7">
									<Input type="number" min="0" />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label sm="5">Order Message</Label>
								<Col sm="7">
									<Input type="textarea" defaultValue="Thanks very much for your order!" />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label sm="5">Listing Type</Label>
								<Col sm="7">
									<Input type="select">
										<option>GTC</option>
									</Input>
								</Col>
							</FormGroup>
							<FormGroup>
								<div className="check">
									<label className="container-check">
										<input type="checkbox" />
										<span className="checkmark"></span>
										Allow override eBay category
									</label>
								</div>
							</FormGroup>
							<FormGroup>
								<div className="check">
									<label className="container-check">
										<input type="checkbox" />
										<span className="checkmark"></span>
										Enable email notifications
									</label>
								</div>
							</FormGroup>
							<FormGroup>
								<div className="check">
									<label className="container-check">
										<input type="checkbox" />
										<span className="checkmark"></span>
										Disable automatic customer feedback
									</label>
								</div>
							</FormGroup>
							<Button color="success">Save</Button>
						</CardBody>
					</Card>
				</Col>
				<Col md={6}>
					<Card>
						<CardBody>
							<CardTitle>eBay Listing Footer Template</CardTitle>
							<FormGroup>
								<Input type="text" placeholder="Title" className="mb-2" />
								<Input type="textarea" placeholder="Description" rows="4" />
							</FormGroup>
							<FormGroup>
								<Input type="text" placeholder="Title" className="mb-2" />
								<Input type="textarea" placeholder="Description" rows="4" />
							</FormGroup>
							<FormGroup>
								<Input type="text" placeholder="Title" className="mb-2" />
								<Input type="textarea" placeholder="Description" rows="4" />
							</FormGroup>
							<FormGroup>
								<div className="check">
									<label className="container-check">
										<input type="checkbox" checked />
										<span className="checkmark"></span>
										Show Footer
									</label>
								</div>
							</FormGroup>
							<Button color="success">Save</Button>
						</CardBody>
					</Card>
				</Col>
      </Row>
      */}
		</React.Fragment>
		);
	}
}

export default EbayListings;
